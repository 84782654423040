window.addEventListener("load", function(event) {
    var date1 = new Date("November 28, 2021 23:59:59 GMT+01:00");
    var date2 = new Date("December 5, 2021 23:59:59 GMT+01:00");
    var date3 = new Date("December 12, 2021 23:59:59 GMT+01:00");
    var date4 = new Date("December 19, 2021 23:59:59 GMT+01:00");
    const today = Date.now();

    if (today < date1.getTime())
        selectDate(0);
    else if (today < date2.getTime() && today > date1.getTime())
        selectDate(1);
    else if (today < date3.getTime() && today > date2.getTime())
        selectDate(2);
    else if (today < date4.getTime() && today > date3.getTime())
        selectDate(3);
});